<template>
  <v-col
    cols="12"
    xs="12"
    sm="6"
    md="6"
    xl="3"
    class="security-card align-stretch d-flex responsive-card"
  >
    <v-card width="100%" elevation="4">
      <v-card-title class="px-6 pb-0 mb-4 h6 font-weight-bold"
        >{{ $t('heading.instance.siteDetails.security.title') }}</v-card-title
      >
      <v-card-text>
        <div class="d-flex justify-space-between align-center">
          <div class="w-100">
            <backup-automatic-switcher
              :asButton="true"
              :small="true"
              :loading="autoBackupSettingsLoading"
              :instance="instance"
              :editable="instance.automatic_backups_editable"
              :automaticBackups="autoBackupSettings"
              @reload-settings="reloadAutobackupSettings"
            />
          </div>
        </div>
        <div class="d-flex justify-space-between align-center mt-3">
          <div class="w-100">
            <p class="mb-0 p-2 black--text">{{ $t('general.ssl') }}:</p>
            <span class="p-5">
              <v-skeleton-loader
                v-if="fetchingSslInfo"
                class="text-skeleton"
                type="text"
              />
              <template v-else>
                <ssl-indicator
                  :fetching="false"
                  :ssl="sslInfo"
                  :isIcon="false"
                />
              </template>
            </span>
          </div>
          <v-btn
            elevation="0"
            class="mt-0 pt-0"
            outlined
            color="gray"
            x-small
            :loading="sslButton.loading"
            @click="
              $emit('action-button-instance-ssl-edit', instance, sslButton)
            "
            v-if="instance.getUserPrivileges('hosting.manage_ssl')"
          >
            <span class="heading--text font-weight-bold p-4">{{ $t('button.manage') }}</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-card-actions class="pt-4" v-if="instance.getUserPrivileges('wordpress.manage_backups')">
        <v-btn
          elevation="0"
          block
          color="primary"
          class="font-weight-bold"
          @click="showCreateModal"
        >
          <v-icon size="16">$update</v-icon>
          {{ $t("button.createBackup") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      :persistent="modalOptions.persistent"
      v-model="modalOptions.open"
      ref="dialog"
      transition="custom-dialog-transition"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()" />
      <basic-modal
        style="width: 560px"
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
      />
    </v-dialog>
  </v-col>
</template>

<script>
import Api from "../../apis/Api";

import ActionModalMixin from "../../mixins/ActionModalMixin";
import BackupActionsMixin from "../../mixins/BackupActionsMixin";
import BackupAutomaticSwitcher from "../backups/BackupAutomaticSwitcher.vue";
import BasicModal from "../modal/BasicModal.vue";
import SslIndicator from "../security/SslIndicator.vue";

export default {
  components: {
    BackupAutomaticSwitcher,
    BasicModal,
    SslIndicator,
  },
  mixins: [ActionModalMixin, BackupActionsMixin],
  data: function () {
    return {
      sslConfirmationOpen: false,
      fetchingSslInfo: false,
      sslInfo: {},
      sslButton: {
        loading: false,
      },
    };
  },
  computed: {
    sslSwitchElement: function () {
      return this.$refs.securitySwitch;
    },
  },
  watch: {
    "modalOptions.persistent": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
  methods: {
    fetchSslInfo() {
      this.fetchingSslInfo = true;
      Api.hosting(this.instance.hostingId)
        .getDetectedCertificate(this.instance.domain)
        .then((response) => {
          if (response) {
            this.sslInfo = {
              certificate_installed: !!response.issuer_name,
              certificate: response.issuer_name ? response : null,
            };
          }
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.fetchingSslInfo = false;
        });
    },
    reloadAutobackupSettings(data) {
      this.$emit("reload-settings", data);
    },
  },
  mounted: function () {
    this.eventBus.$on("ssl-certificate-installed", () => {
      Api.hosting(this.instance.hostingId).resetCache();
      this.fetchSslInfo();
    });
    this.fetchSslInfo();
  },
  props: {
    ssl: Boolean,
    fetching: Boolean,
    instance: Object,
    autoBackupSettings: Object,
    autoBackupSettingsLoading: Boolean,
    eventBus: Object,
  },
};
</script>

<style lang="scss" scoped>
.security-card::v-deep {
  .v-input {
    margin: 0;
    padding: 0;
  }
}
.switch-container {
  cursor: pointer;
  .v-input--switch {
    pointer-events: none;
  }
}

.text-skeleton.v-skeleton-loader::v-deep {
  .v-skeleton-loader__text {
    max-width: 160px;
    height: 13px;
    padding: 2.5px 0;
  }
}
.switch-skeleton.v-skeleton-loader::v-deep {
  .v-skeleton-loader__heading {
    width: 45px;
    height: 28px;
    border-radius: 100px;
    padding: 1px 0;
  }
}
.basic-modal::v-deep {
  ul {
    margin-top: 16px;
    li {
      font-weight: $font-weight-semibold;
      position: relative;
      padding-left: 16px;
      color: #333342;
      &::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #FF7A2F;
        top: calc(50% - 3px);
        left: 0px;
      }
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }
}

.line-16 {
  line-height: $line-height-xxs;
  max-height: 16px;
}

.v-card__title {
  line-height: $line-height-base;
}
</style>