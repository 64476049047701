<template>
  <div>
    <v-container
      class="px-0"
      :class="$vuetify.breakpoint.mobile ? 'mt-3' : 'mt-9'"
    >
      <v-row justify="space-between" align="stretch" class="mb-4">
        <template v-if="!fetching">
          <site-details-info-card :instance="instance"></site-details-info-card>
          <installation-info-card
            :instance="instance"
            @action-update-woocommerce="updateWoocommerce"
            v-on="$listeners"
          ></installation-info-card>

          <security-info-card
            :instance="instance"
            :autoBackupSettings="autoBackupSettings"
            :autoBackupSettingsLoading="automaticBackupsLoading"
            :eventBus="eventBus"
            @reload-settings="reloadAutobackupSettings"
            @action-button-instance-ssl-edit="loadChangeSslCertificateModal"
          ></security-info-card>
          <loading-speed-info-card
            :instance="instance"
          ></loading-speed-info-card>
        </template>
        <template v-else>
          <installation-info-card-skeleton />
          <site-details-info-card-skeleton />
          <security-info-card-skeleton />
          <loading-speed-info-card-skeleton />
        </template>
      </v-row>

      <div class="d-flex justify-space-between align-center mb-8" v-if="instance.getUserPrivileges('general.view_reports')">
        <h4 class="font-weight-light">{{ $t('heading.instance.siteDetails.analytics.title') }}</h4>
        <div>
          <select-dropdown
            :items="periods"
            :value="period"
            :itemText="'text'"
            :itemValue="'value'"
            :size="'xlg'"
            @update:value="changePeriod"
          />
        </div>
      </div>

      <v-row v-if="instance.getUserPrivileges('general.view_reports')">
        <instance-analytics-card-visitors
          :visitorsData="normalizedVisitorsData"
          :sessionTimeData="normalizedSessionTimeData"
          :loading="visitorsDataLoading"
        ></instance-analytics-card-visitors>

        <instance-analytics-card-system
          :bandwidthData="normalizedBandwidthData"
          :storageData="data.storageData"
          :cpuData="data.cpu"
          :loading="bandwidthDataLoading"
        ></instance-analytics-card-system>
      </v-row>
    </v-container>
    <v-dialog
      :persistent="modalOptions.persistent"
      v-model="modalOptions.open"
      ref="dialog"
      transition="custom-dialog-transition"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()" />
      <basic-modal
        style="width: 560px"
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
        ref="modal"
      >
        <template v-slot:custom>
          <div v-if="!fetchingSslInfo" class="mb-4">
            <div
              v-if="serverSslCertificate.certificate"
              class="d-inline-flex justify-center mb-1"
            >
              {{ $t('heading.instance.changeSslModal.serverCertificate') }}:
              <ssl-indicator
                :fetching="false"
                :ssl="serverSslCertificate"
                :isIcon="false"
                class="mx-4 d-inline-block"
              />
            </div>
            <div
              v-if="detectedSslCertificate.certificate"
              class="d-inline-flex justify-center"
            >
              {{ $t('heading.instance.changeSslModal.detectedCertificate') }}:
              <ssl-indicator
                :fetching="false"
                :ssl="detectedSslCertificate"
                :isIcon="false"
                class="ms-4 me-1 d-inline-block"
              />
            </div>
            <AlertSm
              v-if="
                detectedSslCertificate.certificate&&
                detectedSslCertificate.certificate.issuer_name !== serverSslCertificate.certificate.issuer_name
              "
              :class="'alert-sm--primary mt-3 p-1'"
              :text="instance.domain_has_dns_zone ? $t('tooltip.changeSslModal.disableProxy') : $t('tooltip.changeSslModal.cannotChangeCertificate')"
            />
          </div>
        </template>
      </basic-modal>
    </v-dialog>
  </div>
</template>

<script>
import AutomaticBackupsSettingsFetchMixin from "../../mixins/AutomaticBackupsSettingsFetchMixin";
import ChartDataNormalizerMixin from "../../mixins/ChartDataNormalizerMixin";
import PluginActionsMixin from "../../mixins/PluginsActionsMixin";
import EditSslMixin from "../../mixins/EditSslMixin";

import InstallationInfoCard from "../../components/cards/InstallationInfoCard.vue";
import SiteDetailsInfoCard from "../../components/cards/SiteDetailsInfoCard.vue";
import LoadingSpeedInfoCard from "../../components/cards/LoadingSpeedInfoCard.vue";
import SecurityInfoCard from "../../components/cards/SecurityInfoCard.vue";
import SelectDropdown from "../../components/SelectDropdown.vue";
import InstanceAnalyticsCardVisitors from "../../components/cards/InstanceAnalyticsCardVisitors.vue";
import InstanceAnalyticsCardSystem from "../../components/cards/InstanceAnalyticsCardSystem.vue";

import InstallationInfoCardSkeleton from "../../components/cards/InstallationInfoCardSkeleton.vue";
import SiteDetailsInfoCardSkeleton from "../../components/cards/SiteDetailsInfoCardSkeleton.vue";
import LoadingSpeedInfoCardSkeleton from "../../components/cards/LoadingSpeedInfoCardSkeleton.vue";
import SecurityInfoCardSkeleton from "../../components/cards/SecurityInfoCardSkeleton.vue";
import BasicModal from "../../components/modal/BasicModal.vue";

import Api from "@/apis/Api";
import Vue from "vue";
import moment from "moment";
import SslIndicator from "@/components/security/SslIndicator.vue";
import AlertSm from "@/components/alerts/AlertSm.vue";

export default {
  components: {
    AlertSm,
    SslIndicator,
    InstallationInfoCard,
    SiteDetailsInfoCard,
    LoadingSpeedInfoCard,
    SelectDropdown,
    InstanceAnalyticsCardVisitors,
    InstanceAnalyticsCardSystem,
    SecurityInfoCard,
    InstallationInfoCardSkeleton,
    SiteDetailsInfoCardSkeleton,
    LoadingSpeedInfoCardSkeleton,
    SecurityInfoCardSkeleton,
    BasicModal,
  },
  mixins: [
    AutomaticBackupsSettingsFetchMixin,
    ChartDataNormalizerMixin,
    PluginActionsMixin,
    EditSslMixin,
  ],
  data: function () {
    return {
      // fetching: true,
      data: {},
      modalOptions: {},
      modalRender: 0,
      applicationId: this.$route.params.id,
      periods: [
        { value: "last-week", text: this.$t('form.option.period.lastWeek') },
        { value: "last-month", text: this.$t('form.option.period.lastMonth') },
        { value: "last-year", text: this.$t('form.option.period.lastYear') },
      ],
      period: "last-week",
      visitorsDataLoading: true,
      visitorsData: {
        labels: [],
        datasets: [],
      },
      sessionTimeData: {
        labels: [],
        datasets: [],
      },
      bandwidthDataLoading: true,
      bandwidthData: {
        labels: [],
        datasets: [],
      },
      automaticBackupsLoading: true,
      autoBackupSettings: {},
      eventBus: new Vue(),
      sslOffers: [],
      serverSslCertificate: {
        certificate: {},
        certificate_installed: false
      },
      detectedSslCertificate: {
        certificate: {},
        certificate_installed: false
      },
      fetchingSslInfo: false
    };
  },
  computed: {
    normalizedVisitorsData: function () {
      return this.normalizeChartData(this.visitorsData);
    },
    normalizedBandwidthData: function () {
      return this.normalizeChartData(this.bandwidthData);
    },
    normalizedSessionTimeData: function () {
      return this.normalizeChartData(this.sessionTimeData);
    },
  },
  mounted() {
    this.loadData();
    this.$on("ssl-certificate-installed", (item) => {
      this.eventBus.$emit("ssl-certificate-installed", item);
    });
  },
  props: {
    instance: Object,
    fetching: Boolean,
  },
  watch: {
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
  methods: {
    async loadChangeSslCertificateModal(instance, button) {
      await this.fetchSsl();
      this.loadEditSslModalFromInstance(instance, button);
    },
    async fetchSsl() {
      this.fetchingSslInfo = true;
      await this.fetchSslInfo();
      await this.fetchClientCertificate();
      this.fetchingSslInfo = false;
    },
    fetchSslInfo() {
      Api.get(
        `/server-accounts/${this.instance.hostingId}/ssl/settings/${this.instance.domain}`
      )
        .then((response) => {
          this.serverSslCertificate = response.data.data;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
    },
    fetchClientCertificate() {
      Api.hosting(this.instance.hostingId).getDetectedCertificate(this.instance.domain)
        .then(response => {
          if (response) {
            this.detectedSslCertificate.certificate = response;
            this.detectedSslCertificate.certificate_installed = true;
          } else {
            this.detectedSslCertificate.certificate = null;
            this.detectedSslCertificate.certificate_installed = false;
          }
        })
    },
    loadData() {
      if(this.instance.getUserPrivileges('general.view_reports')) {
        this.fetchVisitors();
        this.fetchBandwidth();
        // this.refetchData();
      }
      this.loadAutoBackupsSettings();
    },
    normalizeDate(item) {
      if (this.period === "last-year") {
        return moment(item).format("MMM YYYY");
      }
      return moment(item).format("MMM DD");
    },
    changePeriod(period) {
      if(this.instance.getUserPrivileges('general.view_reports')) {
        // FIXME select-dropdown emits update twice
        if (this.period == period.value) {
          return;
        }
        this.period = period.value;
        this.fetchVisitors();
        this.fetchBandwidth();
      }
    },
    fetchVisitors() {
      this.visitorsDataLoading = true;
      Api.get(
        `/instances/${this.applicationId}/reporting/visitors/${this.period}`
      )
        .then((response) => {
          if (!response.data.data || !response.data.data.visits) {
            return;
          }

          let labels = [];
          let dataset = [];

          for (let [date, amount] of Object.entries(
            response.data.data.visits.records
          )) {
            labels.push(this.normalizeDate(date));
            dataset.push(amount);
          }

          this.visitorsData.labels = labels;
          this.visitorsData.datasets = [dataset];

          this.visitorsData.delta =
            response.data.data.visits.previous_total != 0
              ? parseFloat(
                  (response.data.data.visits.total /
                    response.data.data.visits.previous_total -
                    1) *
                    100
                ).toFixed(2)
              : 100;
          this.visitorsData.delta += "%";
          this.visitorsData.current = response.data.data.visits.total;
          return response;
        })
        .then((response) => {
          if (!response) {
            return;
          }
          if (!response.data.data || !response.data.data.visits_length) {
            return;
          }

          let labels = [];
          let dataset = [];

          for (let [date, amount] of Object.entries(
            response.data.data.visits_length.records
          )) {
            labels.push(this.normalizeDate(date));
            dataset.push(amount);
          }
          this.sessionTimeData.labels = labels;

          this.sessionTimeData.datasets = [dataset];

          this.sessionTimeData.delta =
            response.data.data.visits_length.previous_total != 0
              ? parseFloat(
                  (response.data.data.visits_length.total /
                    response.data.data.visits_length.previous_total -
                    1) *
                    100
                ).toFixed(2)
              : 100;
          this.sessionTimeData.delta += "%";
          this.sessionTimeData.current = this.secondsToHms(
            response.data.data.visits_length.total
          );
          this.sessionTimeData.valueDecorator = this.secondsToHms;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.visitorsDataLoading = false;
        });
    },
    fetchBandwidth() {
      this.bandwidthDataLoading = true;

      Api.get(
        `/instances/${this.applicationId}/reporting/bandwidth/${this.period}`
      )
        .then((response) => {
          if (!response.data.data || !response.data.data.records) {
            return;
          }
          let labels = [];
          let dataset = [];
          for (let [date, amount] of Object.entries(
            response.data.data.records
          )) {
            labels.push(this.normalizeDate(date));
            dataset.push(amount);
          }
          this.bandwidthData.labels = labels;
          this.bandwidthData.datasets = [dataset];
          this.bandwidthData.current = this.formatBytes(
            response.data.data.total
          );
          this.bandwidthData.valueDecorator = this.formatBytes;
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.bandwidthDataLoading = false;
        });
    },
    secondsToHms(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);

      var hDisplay = h > 0 ? h + this.$t('general.time.h') + " " : "";
      var mDisplay = m > 0 ? m + this.$t('general.time.min') + " " : "";
      var sDisplay = s > 0 ? s + this.$t('general.time.sec') : "";
      return hDisplay + mDisplay + sDisplay;
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 " + this.$t('general.size.b');
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = [
        this.$t('general.size.b'),
        this.$t('general.size.kb'),
        this.$t('general.size.mb'),
        this.$t('general.size.gb'),
        this.$t('general.size.tb'),
        this.$t('general.size.pb'),
        this.$t('general.size.eb'),
        this.$t('general.size.zb'),
        this.$t('general.size.yb'),
      ];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    setInstanceDetailsData: function (data) {
      this.data = data;
    },
    // refetchData: async function () {
    //   this.fetching = true;
    //   if (this.subinstance.id) {
    //     const { data, error } = await fetchActiveInstanceDetails(
    //       this.subinstance.id
    //     );

    //     if (error) {
    //       this.$router.push("/page-not-found");
    //     } else {
    //       // TODO
    //       data.usedTheme = "?";

    //       if (this.subinstance.theme_details) {
    //         for (let theme of this.subinstance.theme_details) {
    //           if (theme.status == "active") {
    //             data.usedTheme = theme.title;
    //             break;
    //           }
    //         }
    //       }
    //       data.speedDesktop = "?";
    //       if (this.subinstance.performance_score.desktop) {
    //         data.speedDesktop =
    //           (this.subinstance.performance_score.desktop * 100).toFixed(0) +
    //           "";
    //       }
    //       data.speedMobile = "?";
    //       if (this.subinstance.performance_score.mobile) {
    //         data.speedMobile =
    //           (this.subinstance.performance_score.mobile * 100).toFixed(0) + "";
    //       }

    //       this.setInstanceDetailsData(data);
    //       this.fetching = false;
    //     }
    //   }
    // },
    updateWoocommerce() {
      this.showPluginUpdateModal({
        update_version: this.instance.status.woocommerce_available,
        name: "woocommerce",
      });
    },
  },
};
</script>
